<template>
  <div>
    <div class="elMenu" v-for="item in list" :key="item.id">
      <div  class="itemLine" @click.stop="item.active=!item.active"
           :class="{active:item.active&&item.sub && item.sub.length>0&&item.isRoot,active2:item.active&&item.sub && item.sub.length>0&&!item.isRoot,pdl20:item.isRoot}">
        <span class="elMenutitle" @click="toItem(item)">{{ item.name }}</span>
        <i v-if="item.sub && item.sub.length>0" class="icon"
           :class="item.active?'el-icon-arrow-up':'el-icon-arrow-down'"></i>
      </div>
      <div :class="item.isRoot? 'mgl40':'mgl20'" v-if="item.sub && item.sub.length>0">
        <treeMenu v-show="item.active" :list="item.sub"></treeMenu>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject:['reload'],
  name: "treeMenu",
  props: {
    list: Array
  },
  methods: {
    toItem(e){
      let tohref=e.href
      let fromhref=this.$route.path
      if(tohref == '/index/ill/index'||tohref == '/index/ill/guanMai' ||tohref == ''){//有链接无页面
        return
      }else if(fromhref=='/index/product/detail.html'&&tohref.substring(0,26)=='/index/product/detail.html'){//当前页为商品详情页，再次点击当前商品
        let detailId=tohref.split('?id=')[1]
        this.$router.push({path:'/reFlashPage',query:{id:detailId}})
      }else if(tohref==fromhref){//重复点击当前页面
        this.reload()
      }else{
        this.$router.push(tohref)
      }
    }
  },
  //页面自动刷新
  watch: {
    '$route'() {
      this.reload()
    }
  }
};
</script>
<style lang="less">
.elMenu {
  font-family: PingFangSC-Regular, PingFang SC, Montserrat;
  .elMenutitle {
    border-bottom: none;
  }

  .elMenu {
    .itemLine {
      border-bottom: 0.2777vw solid rgba(32, 191, 208, 0.2) !important;
    }
  }
}

.itemLine {
  padding-right: 5.555vw;
}

.elMenutitle {
  color: #fff;
  font-size: 4.444vw;
  line-height: 13.333vw;
  display: inline-block;

}

.pdl20{
  padding-left: 5.555vw;
}

.mgl20{
  margin-left: 5.555vw;
}

.mgl40{
  margin-left: 11.111vw;
}

.icon {
  color: #fff;
  float: right;
  line-height: 13.333vw !important;
}


.active {
  background: rgba(32, 191, 208, 0.2);
  backdrop-filter: blur(5.555vw);
  border-left: 0.5555vw solid #20BFD0 !important;
  .elMenutitle{
    color: #20BFD0;
  }
}

.active2 {
  .elMenutitle{
    color: #20BFD0;
  }
}
</style>
